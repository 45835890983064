import { useApolloClient } from "@apollo/client"
import { Condition } from "components/pages/Checkout/components/ParentSignupForm/index.styles"
import { Link } from "gatsby"
import gql from "graphql-tag"
import React, { useCallback, useMemo, useState } from "react"
import styled, { useTheme } from "styled-components"
import { COUNTRIES } from "../../../../constants"
import Button from "../../../UI/Button"
import Checkbox from "../../../UI/Checkbox"
import Input from "../../../UI/Input"
import Select from "../../../UI/Select"

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  border: 2px solid ${(props: any) => props.theme.primary};
  padding: 35px 25px;
  text-align: center;
  border-radius: 13px;
  max-width: 430px;
  margin: 0 auto;
  > :first-child {
    color: ${(props: any) => props.theme.secondary};
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  padding-bottom: 60px;
  > :last-child {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
  }
`

const StyledCondition = styled(Condition)`
  a {
    color: ${(props: any) => props.theme.primary};
  }
`

const FormError = styled.div`
  color: #f00;
  margin-bottom: 15px;
`

const Form = styled.div`
  > * {
    margin-bottom: 10px;
  }
  > :last-child {
    margin-bottom: 0;
  }

  > .form--field {
    > .form-field--label {
      text-align: left;
      color: ${(props: any) => props.theme.secondary};
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 4px;
    }
  }
`

interface IProps {
  onSubmit: (data: any) => void
}

interface IData {
  firstName: string
  lastName: string
  email: string
  countryCode: string
  schoolName: string
  nbStudents?: number
  yearGroup?: number
  termsAccepted: boolean
}

const DetailsForm = (props: IProps) => {
  const theme: any = useTheme()
  const client = useApolloClient()
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<IData>({
    firstName: "",
    lastName: "",
    countryCode: "US",
    email: "",
    yearGroup: undefined,
    nbStudents: undefined,
    schoolName: "",
    termsAccepted: false,
  })

  const canSubmit = useMemo(() => {
    const emailRe =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return (
      data.firstName !== "" &&
      data.lastName !== "" &&
      data.countryCode &&
      emailRe.test(data.email) &&
      parseInt(`${data.yearGroup}`, 10) >= 0 &&
      parseInt(`${data.nbStudents}`, 10) >= 5 &&
      data.schoolName !== "" &&
      data.termsAccepted
    )
  }, [data])

  const checkEmailIsFree = async (email: string) => {
    const { data } = await client.query({
      query: gql`
        query freeEmail($email: String!) {
          freeEmail(email: $email)
        }
      `,
      fetchPolicy: "network-only",
      variables: {
        email,
      },
    })
    if (!data.freeEmail) {
      setError(
        "This email is already registered, please contact us at teachers@nightzookeeper.com."
      )
    }
    return data.freeEmail
  }

  const update = payload => {
    setData(d => ({
      ...d,
      ...payload,
    }))
  }

  const onSubmit = useCallback(async () => {
    if (!canSubmit) return
    setError(null)
    const freeEmail = await checkEmailIsFree(data.email)
    if (freeEmail) {
      props.onSubmit(data)
    }
  }, [canSubmit])

  return (
    <Wrapper>
      <div>Your details</div>
      {error && <FormError>{error}</FormError>}
      <Form>
        <Input
          borderColor={theme.primary}
          placeholder="Your First Name"
          value={data.firstName}
          onChange={e => update({ firstName: e.target.value })}
        />
        <Input
          borderColor={theme.primary}
          placeholder="Your Last Name"
          value={data.lastName}
          onChange={e => update({ lastName: e.target.value })}
        />
        <Select
          theme={{
            selectedColor: theme.primary,
            focusedColor: `${theme.primary}33`,
            borderColor: theme.primary,
          }}
          value={data.countryCode}
          placeholder="Select your country"
          options={COUNTRIES}
          onChange={value => update({ countryCode: value })}
        />
        <div className="form--field">
          <div className="form-field--label">Use your school email address</div>
          <Input
            borderColor={theme.primary}
            placeholder="Your Email"
            value={data.email}
            onChange={e => update({ email: e.target.value })}
          />
        </div>

        <Input
          borderColor={theme.primary}
          min={5}
          placeholder="Number of students"
          type="number"
          value={data.nbStudents}
          onChange={e => update({ nbStudents: parseInt(e.target.value, 10) })}
        />
        <Select
          placeholder="Age of students"
          theme={{
            selectedColor: theme.primary,
            focusedColor: `${theme.primary}33`,
            borderColor: theme.primary,
          }}
          options={[
            { value: 0, label: "5 years old" },
            { value: 1, label: "6 years old" },
            { value: 2, label: "7 years old" },
            { value: 3, label: "8 years old" },
            { value: 4, label: "9 years old" },
            { value: 5, label: "10 years old" },
            { value: 6, label: "11 years old" },
            { value: 7, label: "12+ years old" },
          ]}
          value={parseInt(`${data.yearGroup}`, 10)}
          onChange={value => update({ yearGroup: value })}
        />
        <div className="form--field">
          <div className="form-field--label">Use your full school name</div>
          <Input
            borderColor={theme.primary}
            placeholder="School Name"
            value={data.schoolName}
            onChange={e => update({ schoolName: e.target.value })}
          />
        </div>
        <StyledCondition>
          <Checkbox
            checked={data.termsAccepted}
            onChange={value => update({ termsAccepted: value })}
            color={theme.primary}
          />
          <p>
            I am over 18 years old and I agree to the following{" "}
            <Link to="/terms-and-conditions">Terms & Conditions</Link> and{" "}
            <Link to="/terms-and-conditions">Privacy Policy</Link>
          </p>
        </StyledCondition>
      </Form>
      <Button
        theme="primary"
        size="regular"
        onClick={onSubmit}
        disabled={!canSubmit}
      >
        Submit
      </Button>
    </Wrapper>
  )
}

export default DetailsForm
